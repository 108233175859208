import { Routes } from "@angular/router"
//import { UrlSegment } from "@angular/router"

import { AuthPipe, canActivate } from '@angular/fire/auth-guard'
import { map, pipe } from "rxjs"
import { isDisposableEmail } from "@nx-superprep/utils"
import { isDevMode } from "@angular/core"
//import { hasCustomClaim, isNotAnonymous } from '@angular/fire/auth-guard'

//import { PagenotfoundComponent } from "@nx-superprep/shared"
//import { AppComponent } from "./app.component"

// custom AuthPipes
//const passThrought = (() => of(true)) as AuthPipe
//const passThroughtAnonymous = ((user: User) => map(() => !!user && isGeneratedAnonymousEmail(user.email)) as AuthPipe)
const authorized: AuthPipe = map((user) => !!user && !!user.email && !isDisposableEmail(user.email))
// custom redirect
const redirectUnauthorizedTo: (redirect: string | any[]) => AuthPipe = (redirect) => pipe(authorized, map((authorized) => authorized || redirect))
const redirectAuthorizedTo: (redirect: string | any[]) => AuthPipe = (redirect) => pipe(authorized, map((authorized) => (authorized && redirect) || true))
// firebase auth guard fn
const redirectLoggedInToDashboard =  () => redirectAuthorizedTo(['dashboard'])
const redirectUnauthorizedToWebsite = () => redirectUnauthorizedTo(['website'])

//const redirectAnonymousToDemo = () => isNotAnonymous
//const adminOnly = () => hasCustomClaim('admin')
//const belongsToAccount = (next: { params: { id: unknown; }; }) => hasCustomClaim(`account-${next.params.id}`)

export const appRoutes: Routes = [
  { path: '', redirectTo: 'website', pathMatch: 'full' },
  { path: 'legal', redirectTo: 'legal/tacos', pathMatch: 'full' },
  { path: 'terms', redirectTo: 'legal/tacos', pathMatch: 'full' },
  { path: 'privacy', redirectTo: 'legal/privacy', pathMatch: 'full' },
  { path: 'unsubscribe', redirectTo: 'legal/unsubscribe', pathMatch: 'full' },
  // shortcuts
  { path: 'home', redirectTo: '/dashboard?activeTab=home', pathMatch: 'full' },
  { path: 'library', redirectTo: '/dashboard?activeTab=library', pathMatch: 'full' },
  { path: 'performance', redirectTo: '/dashboard?activeTab=performance', pathMatch: 'full' },
  // secondary entry points
  { path: 'legal/:id', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent) },
  { path: 'about', loadComponent: () => import('./features/about/about.component').then(m => m.AboutComponent) },
  { path: 'login', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent) },
  // demo & tutorial
  { path: 'demo', loadComponent: () => import('./features/demo/demo.component').then(m => m.DemoComponent), outlet: 'primary' },
  { path: 'get-started', loadComponent: () => import('./features/get-started/get-started.component').then(m => m.GetStartedComponent), outlet: 'primary' },
  // main entry points
  { path: 'website', loadComponent: () => import('./features/website/website.component').then(m => m.WebsiteComponent), outlet: 'primary', ...canActivate(redirectLoggedInToDashboard) },
  { path: 'dashboard', loadComponent: () => import('./features/popstudy/popstudy.component').then(m => m.PopstudyComponent), outlet: 'primary', ...canActivate(redirectUnauthorizedToWebsite) },
  // proxy component for param actions
  { path: 'quiz/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'study/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'done/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'complete/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'status/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'pause/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'settings/:moduleId', loadComponent: () => import('./features/redirect/redirect.component').then(m => m.RedirectComponent), ...canActivate(redirectUnauthorizedToWebsite) },
  // content manager
  { path: 'brushup', loadComponent: () => import('./features/brushup/brushup.component').then(m => m.BrushupComponent), outlet: 'primary', ...canActivate(redirectUnauthorizedToWebsite) },
  { path: 'resources', loadComponent: () => import('./features/resources/resources.component').then(m => m.ResourcesComponent), outlet: 'primary', ...canActivate(redirectUnauthorizedToWebsite) },
  // tests
  isDevMode()
  ? { path: 'whisper', loadComponent: () => import('./features/whisper/whisper.component').then(m => m.WhisperComponent), outlet: 'primary', ...canActivate(redirectUnauthorizedToWebsite) }
  : {},
  // fallback
  { path: '**', redirectTo: 'website', pathMatch: 'full' }
]
