import { LibrarySection, LibrarySectionItem, PopDataType } from "../../back-end"

export interface NavigationState {
  action?: NavigationAction,
  current: NavigationRoute,
}

export interface NavigationRoute {
  route: LibraryRouteType|DashboardRouteType|PerformanceRouteType|'status',
  type: NavigationType,
  title?: string
}

export type PerformanceRouteType = 'graph'
export type HeaderTemplateType = typeof HeaderTemplateValues[number]
export type DashboardRouteType = HeaderTemplateType|'profile'|'legal'
export type NavigationTitleType = string | [string, string, string] | undefined
export type NavigationType = 'root' | 'back' | 'nav' | 'close' | 'close-start' | 'close-end'
export type NavigationAction = 'close'|'back'|'forward'|'title'
export type NavigationTabName = 'start'|'center'|'end'

export interface LibraryNavigation {
  navigation: NavigationState,
  parent?: LibraryNavigation,
  selection?: LibrarySelection,
}

export interface LibrarySelection {
  section?: LibrarySection,
  item?: LibrarySectionItem,
  detail?: LibrarySelection,
  didChange?: boolean,
}

export type LibraryRouteType = ReturnType<typeof routeForSelection>

export const HeaderTemplateValues = <const> ['home','library','performance','brushup','resources']

export const routeForSelection = (selection: LibrarySelection) => {
  if (!selection.item) { return (selection.section?.subTitle || selection.section?.title?.toLowerCase() === 'your custom lessons') ? 'collections' : 'subjects' }
  switch(selection.item.dataType) {
    case PopDataType.CategoryTemplate: return 'collections'
    case PopDataType.SubjectTemplate: return 'subjects'
    case PopDataType.GroupTemplate: return 'groups'
    case PopDataType.LevelTemplate: return 'groups'
    case PopDataType.ModuleTemplate: return 'lessons'
    case PopDataType.CardTemplate: return 'cards'
    default: return ('parentCategoryIds' in selection.item) ? 'subjects' : 'library'
  }
}

export const concatCurrentPath = (nav: LibraryNavigation|undefined) => {
  let path = ''
  const detail = nav?.selection?.detail && ('id' in nav.selection.detail) ? nav.selection.detail.id ?? '' : ''

  // Traverse from the current navigation up to the root parent
  while (nav) {
    // Append the current route's title or route name to the path
    //const currentTitle = nav.navigation.current.title || nav.navigation.current.route
    // Append the current selected item id or route's title or name to the path
    const itemId = nav.selection?.item ? nav.selection?.item.itemId ?? ('id' in nav.selection?.item ? nav.selection?.item.id : '') ?? '' : ''
    const currentTitle = itemId || nav.navigation.current.title || nav.navigation.current.route
    // Add the title/route to the path
    if (currentTitle) { path = `${currentTitle}${path?'/':''}${path??''}` }

    // Move to the parent navigation (if any)
    nav = nav.parent
  }

  return `${path}${detail?'/':''}${detail}`
}

