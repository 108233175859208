import { Injectable, isDevMode } from '@angular/core'

import { LogLevelDesc, log } from '@nx-superprep/utils'
import { FirebaseConfig, getFirebaseConfig } from './firebase-config'
import { HttpClient } from '@angular/common/http'
import { firstValueFrom, tap } from 'rxjs'
import { environment } from '@nx-superprep/environment'
import { isEmpty } from 'lodash-es'
import npmPackage from '../../../../../package.json'

export interface LearningSet {
  category: string,
  subject: string,
  title: string,
  description: string,
  group: string,
  modules: never[],
  levels: never[],
}

export interface CardCount {
  cardID?: string,
  moduleID?: string,
  count: number,
}

export interface BackendConfig {
  name: string,
  version?: string;
  ltVersion?: Record<string, string>,
  host: string,
  origin: string,
  environment: BackendEnvironment,
  isProduction: boolean,
  logLevel: LogLevelDesc,
  website?: URL,
  firebase: FirebaseConfig,
  application: string,
  timezone?: string
}

export type BackendEnvironment = 'Local'|'Emulator'|'Development'|'Staging'|'Production';

@Injectable({providedIn: 'root'})
export class BackendConfigService {

  constructor(protected http: HttpClient) {}

  applicationSkin: string|undefined = undefined

  config: BackendConfig = getConfig(environment.config)

  initConfig() {
    return fetchConfig(this.config)
    .then(result => {
      log.debug(`🟢using backend${result?'':' failover'} config`, result??this.config)
      this.config = getConfig(environment.config)
      return this.config;
    })
  }

  fetchVersion() { return fetchVersion() }

  get version() {
    return this.config.version ?? ''
  }
  get host() {
    return this.config.host ?? 'localhost'
  }
  get environment() {
    return this.config.environment ?? 'Local'
  }
  get logLevel() {
    return this.config.logLevel ?? 'error'
  }
  get isProduction() {
    return this.config.isProduction
  }
  get firebase() {
    return this.config?.firebase
  }

  get origin() {
    return this.config?.origin
  }

  postLog(data: any) {
    navigator.onLine
      ? firstValueFrom(this.http.post('/api/logger', data).pipe(tap(result => log.debug(result))))
      .then(result => log.debug(result))
    : Promise.resolve()
  }
}

function fetchVersion(): Promise<string> {
  return navigator.onLine
    ? fetch('/api/version')
    .then(result => result.json())
    .then(result => result.version)
  : Promise.resolve()
}

function getConfig(data: Partial<BackendConfig> = {}): BackendConfig {
  const host = data.host ?? 'localhost'
  const origin = data.origin ?? `http://${host}`
  const environment = data.environment ?? (host.startsWith('localhost') ? 'Local' : 'Production')
  const isProduction = (['Staging','Production'].includes(environment))
  const version = data.version
  const ltVersion = data.ltVersion
  const website = undefined
  const firebase = getFirebaseConfig(data.firebase)
  const application = data.application ?? ''
  const timezone = data.timezone
  // log level overrides
  const logLevel = data.logLevel ?? (isProduction ? 'error' : 'debug')
  const name = `PopStudy${isProduction ? '' : ' Dev'} Web`
  //
  const config: BackendConfig = {
    name,
    version,
    ltVersion,
    host,
    origin,
    environment,
    isProduction,
    logLevel,
    website,
    firebase,
    application,
    timezone
  }
  return config
}

export async function fetchConfig(data?: Partial<BackendConfig> | undefined): Promise<BackendConfig|undefined> {
  let config = getConfig(isEmpty(data)
    ? {
        host: window.location.hostname ?? 'localhost',
        origin,
        version: npmPackage.version,
      }
    : data)

  log.setLevel(config?.logLevel??'debug')

  const url = `${isDevMode() ? config?.host??'' : ''}${environment.api.url}`

  try {
      await fetch(`${url}/config`)
      .then(resp => {
        if (resp?.ok) {
          return resp.json()
        } else {
          log.warn('⚠️Backend returned an error response for config fetch')
          return Promise.resolve()
        }
      })
      .then(result => {
        // Merge the result with the current config
        config = getConfig(result??config)
        log.info(`${result ? '👍' : '👎'}got backend${result?'':' failover'} config`, result??config)
        // Save to local storage if available
        environment.config = config
        return config
      })
      .catch((reason) => {
        // Handle fetch errors
        log.error('⚠️config fetch error', reason)
        return config
      })
      .finally(() => {
        // Ensure final log level and upgrade local config
        log.setLevel(config?.logLevel??'error')
        environment.config = config
        log.debug(`✏️set backend config`, config)
      })
      return config
    }
    catch (reason) {
      return config
    }
}
