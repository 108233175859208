import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {

  static POP = 'pop_'
  static POPUSER = `${BrowserStorageService.POP}user`
  static POPCONFIG = `${BrowserStorageService.POP}config`
  static POPVOLUME = `${BrowserStorageService.POP}volume`

  static getLocalUser() {
    return JSON.parse(this.getItem(BrowserStorageService.POPUSER)??'{}')
  }
  static setLocalUser(user: unknown) {
    BrowserStorageService.setItem(BrowserStorageService.POPUSER, JSON.stringify(user))
  }
  static removeLocalUser() {
    BrowserStorageService.removeItem(BrowserStorageService.POPUSER)
  }

  static getLocalConfig() {
    return JSON.parse(localStorage.getItem(BrowserStorageService.POPCONFIG)??'{}')
  }
  static setLocalConfig(config: unknown) {
    localStorage.setItem(BrowserStorageService.POPCONFIG, JSON.stringify(config))
  }

  static upgradeLocalConfig(data: unknown) {
    const user = BrowserStorageService.getItem('user')
    const config = BrowserStorageService.getItem('config')
    const volume = BrowserStorageService.getItem('volume')
    if (user) { BrowserStorageService.setLocalUser(user); BrowserStorageService.removeItem('user') }
    if (config) { BrowserStorageService.setLocalConfig(config); BrowserStorageService.removeItem('config') }
    if (volume) { BrowserStorageService.setLocalConfig(volume); BrowserStorageService.removeItem('volume') }
    BrowserStorageService.setLocalConfig(data)
    return BrowserStorageService.getLocalConfig()
  }

  static removeLocalConfig() {
    BrowserStorageService.removeItem(BrowserStorageService.POPCONFIG)
  }

  static getLocalVolume() {
    return Number(BrowserStorageService.getItem(BrowserStorageService.POPVOLUME))||1
  }
  static setLocalVolume(volume: number) {
    BrowserStorageService.setItem(BrowserStorageService.POPVOLUME, volume.toString())
  }
  static removeLocalVolume() {
    BrowserStorageService.removeItem(BrowserStorageService.POPVOLUME)
  }

    static getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  static setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  origin: string | undefined
  storage: Storage | undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.storage = isPlatformBrowser(this.platformId) ? localStorage : undefined
    this.origin = isPlatformBrowser(this.platformId) ? window.location.origin.slice() : undefined
  }

  getItem(key: string) { this.storage ? BrowserStorageService.getItem(key) : undefined }
  setItem(key: string, value: string) { return this.storage ? BrowserStorageService.setItem(key, value) : undefined }
  removeItem(key: string) { return this.storage ? BrowserStorageService.removeItem(key) : undefined }
  getLocalUser() { return this.storage ? BrowserStorageService.getLocalUser() : undefined }
  setLocalUser(value: unknown | undefined) { return this.storage ? BrowserStorageService.setLocalUser(value) : undefined }
  removeLocalUser() { return this.storage ? BrowserStorageService.removeLocalUser() : undefined }
  getLocalConfig() { return this.storage ? BrowserStorageService.getLocalConfig() : undefined }
  setLocalConfig(value: unknown) { return this.storage ? BrowserStorageService.setLocalConfig(value) : undefined }
  removeLocalConfig() { return this.storage ? BrowserStorageService.removeLocalConfig() : undefined }
  getLocalVolume() { return this.storage ? BrowserStorageService.getLocalVolume() : 1 }
  setLocalVolume(value: number) { return this.storage ? BrowserStorageService.setLocalVolume(value) : undefined }
  removeLocalVolume() { return this.storage ? BrowserStorageService.removeLocalVolume() : undefined }
  upgradeLocalConfig(data: unknown) { return this.storage ? BrowserStorageService.upgradeLocalConfig(data) : undefined }
}
