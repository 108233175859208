import { ApplicationRef, Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { log } from '@nx-superprep/utils'
import { concat, interval } from 'rxjs'
import { first } from 'rxjs/operators'

@Injectable({providedIn: 'root'})
export class UpdateService {

  constructor(appRef: ApplicationRef, private updates: SwUpdate) {
    log.debug(`${updates.isEnabled ? '🟢' : '🔴'}service worker is${updates.isEnabled ? '' : ' not'} enabled`)
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true))
    const periodically$ = interval(0.16 * 60 * 60 * 1000)
    const periodicallyOnceAppIsStable$ = concat(appIsStable$, periodically$)

    periodicallyOnceAppIsStable$.subscribe(() => this.check())
    // log
    updates.versionUpdates.subscribe(event => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          log.debug(`Downloading new app version with hash: ${event.version.hash}`)
          break
        case 'VERSION_READY':
          log.debug(`current version hash is ${event.currentVersion.hash} available version hash is ${event.latestVersion.hash}`)
          this.activate()
          break
        case 'VERSION_INSTALLATION_FAILED':
          log.error(`error ${event.error} installing version ${event.version.hash}`)
          break
        case 'NO_NEW_VERSION_DETECTED':
          log.debug(`current version hash is ${event.version.hash}`)
          break
        default:
      }
    })
    // handle unrecoverable state
    updates.unrecoverable.subscribe(event => {
      this.notifyUser(
        `An error occurred that we cannot recover from:\n${event.reason}\n\n` +
        'Please reload the page.')
    })
  }

  get available() { return this.updates.versionUpdates }
  get activated() { return this.updates.versionUpdates }
  get unrecoverable() { return this.updates.unrecoverable }

  private _autoUpdate = true
  public get autoUpdate() {
    return this._autoUpdate
  }
  public set autoUpdate(value) {
    this._autoUpdate = value
    this.check()
  }

  notifyUser(message: string) {
    log.debug(message)
  }

  check() {
    return (this.updates.isEnabled ? this.updates.checkForUpdate() : Promise.resolve(false)).catch(error => {log.error(error); return false})
  }

  activate() {
    if (!this.autoUpdate || !this.updates.isEnabled) { return }
    const location = document.location.toString()
    log.debug(location)
    this.updates.activateUpdate().then(() => window.location.reload())
  }
}
