/* eslint-disable max-len */

/**
 * Utility functions for common operations in the application.
 * Includes functions for color manipulation, string formatting, array operations, etc.
 */

// Import Angular core and external dependencies
import { isDevMode } from '@angular/core'
import { identity, sortBy } from 'lodash-es'
import { datelib } from './date-adapter'

export const _isNewDesign = true || isDevMode()

// Define constants for special characters
export const NARROW_NO_BREAK_SPACE = '\u202F'
export const NO_BREAK_SPACE = '\u00A0'
export const THIN_SPACE = '\u2009'
export const FIGURE_SPACE = '\u2007'
export const WORD_JOINER = '\u2060'

// Define utility functions for common operations

/**
 * Calculate the modulo operation of two numbers.
 * @param x The dividend.
 * @param m The divisor.
 * @returns The result of x mod m.
 */
export const modulo = (x: number, m: number) => ((x % m) + m) % m
/**
 * Convert a value to a default number if it's NaN.
 * @param x The value to convert.
 * @param d The default value to use if x is NaN.
 * @returns The value converted to a number, or the default value if x is NaN.
 */
export const toDefaultNumber = (x: unknown, d: number) => Number.isNaN(Number(x)) ? d : Number(x)
/**
 * Return an array containing the elements common to both input arrays.
 * @param items1 The first array of elements.
 * @param items2 The second array of elements.
 * @returns An array containing the common elements between items1 and items2.
 */
export const intersection = <T>(items1: T[] = [], items2: T[] = []) =>
  items1?.length > 0 && items2.length > 0
    ? items1.filter((v) => items2.includes(v))
    : []

/**
 * Convert a value to an array.
 * @param item The value to convert to an array.
 * @returns An array containing the value, or an empty array if the value is null or undefined.
 */
export const toArray = <T>(item?: T | T[]): T[] => item instanceof Array ? item : item ? [item as T] : []
export const toUniqueArray = <T>(item?: T | T[]): T[] => unique(toArray(item))
export const toNonNullableArray = <T>(item?: T | T[]): NonNullable<T>[] => toArray(item).filter(a => a) as NonNullable<T>[]
export const toUniqueNonNullableArray = <T>(item?: T | T[]): NonNullable<T>[] => unique(toNonNullableArray(item))
export const toLowerCaseArray = (item?: string | string[]) => toArray(item).map((s) => (s ? s.toLowerCase() : s))
export const toNonNullableLowerCaseArray = (item?: string | string[]): NonNullable<string>[] => toNonNullableArray(item).map(s => s.toLowerCase())
export const toUniqueNonNullableLowerCaseArray = (item?: string | string[]): NonNullable<string>[] => unique(toNonNullableLowerCaseArray(item))

/**
 * Return an array of objects uniques by reference
 *
 * @param items an array of objects
 */
export const unique = <T>(items: T[]) => Array.from(new Set(items))
export const uniqueByKey = <T>(items: T[], key: string) => {
  const seen = {}
  return items.filter((item) => {
    const k = Object(item)[key]
    // eslint-disable-next-line no-prototype-builtins
    return Object.prototype.hasOwnProperty.call(seen, k) ? false : (Object(seen)[k] = true)
  })
}
export const uniqueByKeys = <T>(items: T[], keys: string[]) => {
  if (!keys || keys.length === 0) {
    return Array.from(new Set(items))
  }
  const kvArray: [string, T][] = items.map((value) => {
    const key = keys.map((k) => Object(value)[k]).join('|')
    return [key, value]
  })
  const map = new Map(kvArray)
  return Array.from(map.values())
}

/**
 * Group items by property value
 *
 * @param xs items to group
 * @param key property to use as key
 */
export const groupBy = <T>(xs: T[], key: string): { string?: T[] } =>
  xs instanceof Array
    ? xs.reduce((rv, x) => {
        (Object(rv)[Object(x)[key]] = Object(rv)[Object(x)[key]] || []).push(x)
        return rv
      }, {})
    : {}

export const compare = (
  a: number | string | boolean,
  b: number | string | boolean,
  isAsc = true
) => (a < b ? -1 : 1) * (isAsc ? 1 : -1)

export const localeCompare = (
  a: number | string,
  b: number | string,
  isAsc = true
) => (String(a).localeCompare(String(b), undefined, {numeric: true, sensitivity: 'base'}) * (isAsc ? 1 : -1))


export const compareByAlphaNumKey = <E>(a: E, b: E, key: string, isAsc = true): number => {
  const aString = Object(a)[key] as string ?? ''
  const bString = Object(b)[key] as string ?? ''
  return aString.localeCompare(bString, undefined, {numeric: true, sensitivity: 'base'}) * (isAsc ? 1 : -1)
}

export const sortByArray = <T, U>(
  source: T[],
   by: U[],
   sourceTransformer: (item: T) => U = identity
) => {
  const indexesByElements = new Map(by.map((item, idx) => [item, idx]));
  const orderedResult = sortBy(source, (p) => indexesByElements.get(sourceTransformer(p)));
  return orderedResult;
}

export const partition = <T>(array: T[], predicate: (arg0: T) => boolean): [T[], T[]] =>
  array.reduce(
    (result, e) => {
      result[predicate(e) ? 0 : 1].push(e)
      return result
    },
    [[] as T[], [] as T[]],
  )

/**
 * Compare two scalar arrays for content
 *
 * @param a array of entities to compare
 * @param b array of entities to compare
 * @param key single | array of keys to use for compare
 */
export const isSameContent = <T>(a: T[], b: T[], key: string|string[] = []) => {
  if (a?.length !== b?.length) return false
  const keys = toUniqueNonNullableArray(key)
  return keys.length > 0
    ? a.every(aValue => {
      const aObject = Object(aValue)
      return !!(b.find(bValue => {
        const bObject = Object(bValue)
        return keys.every(k => bObject[k] === aObject[k])
      }))
    })
    : a.every((value) => b.includes(value))
}

/**
 * Compare two scalar arrays for content and order
 *
 * @param a array of entities to compare
 * @param b array of entities to compare
 * @param key single | array of keys to use for compare
 */
export const isSameContentOrder = <T>(a: T[], b: T[], key: string|string[] = []) => {
  if (a?.length !== b?.length) return false
  const keys = toUniqueNonNullableArray(key)
  return keys.length > 0
    ? a.every((aValue, index) => {
      const aObject = Object(aValue)
      const bObject = Object(b[index])
      return keys.every(k => aObject[k] === bObject[k])
    })
    : a.every((value, index) => value === b[index])
}

/**
 * Shuffle an array in place using the Fisher-Yates algorithm.
 * @param array The array to shuffle.
 */
export const shuffleArray = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
}
/**
 * shuffle using sort
 */
export const shuffledArray = <T>(array: T[]) =>
  array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
/**
 * Extensions
 */
declare global {
  interface String {
    toTitleCase: () => string
  }
  interface Array<T> {
    /**
     * array equality.
     *
     * @param array an Array to compare.
     */
    equals: (array: T[]) => boolean
    rotate: (count: number) => T[]
    shuffle: () => T[]
    shuffled: () => T[]
  }
}

// attach the .toTitleCase method to String's prototype to call it on any string
String.prototype.toTitleCase = function() {
  return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

/**
 * shuffle in place using fisher yates algorithm: http://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 */
Array.prototype.shuffle = function() {
 for (let i = this.length - 1; i > 0; i--) {
   const j = Math.floor(Math.random() * (i + 1));
   [this[i], this[j]] = [this[j], this[i]]
 }
 return this
}
/**
 * return a shuffled copy
 */
Array.prototype.shuffled = function() {
  return this.slice().shuffle()
 }

/*
// attach the .rotate method to Array's prototype to call it on any array
Array.prototype.rotate = (() => {
  const unshift = Array.prototype.unshift
  const splice = Array.prototype.splice

  return function(count: number): [] {
    // eslint-disable-next-line no-bitwise
    const len = (this.length >>> 0)
    // eslint-disable-next-line no-bitwise
    count = (count >> 0)

    unshift.apply(this, splice.call(this, count % len, len))
    return this
  }
})()
*/
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function(array) {
  // if the other array is a falsy value, return
  if (!array) {
    return false
  }

  // compare lengths - can save a lot of time
  if (this.length !== array.length) {
    return false
  }

  for (let i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) {
        return false
      }
    } else if (this[i] !== array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', { enumerable: false })

/**
 * Get the appropriate greeting message based on the time of day.
 * @returns A greeting message such as 'Good morning', 'Good afternoon', or 'Good evening'.
 */
export const getGreetings = (): string => {
  const now = datelib()
  const startOfDay = now.startOf('day')
  let found = 0
  if (now.isAfter(startOfDay.clone().add(12, 'hour'))) {
    found = 1
  }
  if (now.isAfter(startOfDay.clone().add(17, 'hour'))) {
    found = 2
  }
  const greetings = ['morning', 'afternoon', 'evening']
  return 'Good ' + greetings[found]
}

// Define formatter functions for numbers, durations, and dates...

export const numberFormatter = (num: number, digits = 0, sub = true) => {
  const _tier = num ? Math.floor(Math.log10(Math.abs(num)) / 3) : 0
  const value = +(num / Math.pow(10, _tier * 3))
  const units = _tier > 0 ? ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'] : ['', 'm', 'µ', 'n', 'p', 'f', 'a', 'z', 'y']
  const tier = Math.abs(_tier) < units.length ? Math.abs(_tier) : 0
  const result = (_tier >= 0 || sub) ? value.toFixed(value > 1 ? digits : 0) + units[tier] : ''
  return result
}
/**
 * return the formatted duration of time
 * @param time duration to format
 * @param short use short unit names, default to true
 * @param single return a single unit only, default to true
 * @returns
 */
export const durationFormatter = (time = 0, short = true, single = true): string => {
  const duration = datelib.duration(time, 's')
  const h = duration.asHours()
  const m = h < 1 || !single ? duration.asMinutes() : 0
  const s = m < 1 || !single ? duration.asSeconds() : 0
  const h_label = short ? 'h' : `${NO_BREAK_SPACE}hour${Math.round(h) >= 2 ? 's' : ''}`
  const m_label = short ? 'm' : `${NO_BREAK_SPACE}min${Math.round(m) >= 2 ? 's' : ''}`
  const s_label = short ? 's' : `${NO_BREAK_SPACE}second${Math.round(s) >= 2 ? 's' : ''}`
  const result = h >= 1
  ? `${h.toFixed()}${h_label}${single ? '' : duration.minutes() > 1 ? duration.format(`m[${m_label}]`):duration.seconds() > 1 ? duration.format(`s[${s_label}]`):''}`
  : m >= 1
  ? `${m.toFixed()}${m_label}${single ? '' : duration.seconds() > 1 ? duration.format(`s[${s_label}]`):''}`
  : s >= 1
  ? `${duration.format(`s[${s_label}]`)}`
  : `0${s_label}`
  return result
}

export const dateFormatter = (date: Date): string => {
  return datelib(date).fromNow()
}

export type NestedKeyOf<T extends object> = {[Key in keyof T & (string | number)]: T[Key] extends object
  ? `${Key}` | `${Key}.${NestedKeyOf<T[Key]>}`
  : `${Key}`
}[keyof T & (string | number)]

export function getValue<T extends object>(object: T, path: NestedKeyOf<T>) {
  const keys = path.split('.')
  let result = Object(object)
  for (const key of keys) {
    result = result[key]
  }
  return result
}

export function defined(property?: string | string[]) {
  switch (property?.constructor) {
    case String:
      return (property as string) !== 'N/A'
        ? (property as string)
          .replace(/{(\/?\w+)}/g, '<$1>')
          .replace(/{()}/g, '&nbsp;')
          .replace(/@ /g, ', ')
        : undefined
    case Array:
      return property.length > 0 && property[0] !== '[All]' ? (property as string[]).join('|') : ''
    default:
      return undefined
  }
}

export const randomColor = (min = 0, max = 16777215) => {
  return '#' + Math.floor(Math.random()*(max - min)).toString(16)
}

export const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).slice(-2)
  }
  return colour
}

export const normalizeStat = (value: number|string, type?: 'number'|'duration'|undefined): string => {
  switch(type) {
    case 'number': return numberFormatter(Number(value)||0)
    case 'duration': return durationFormatter(Number(value)||0)
    default: return (Number(value)||0).toString()
  }
}

// Fit text into container
export const isOverflown = <T extends {scrollHeight: number, clientHeight: number}>(e: T) => e.scrollHeight > e.clientHeight

 /**
 * Check the element if it is text-overflow
 * @param elementId
 */
 export const isTextOverflow = (elementId: string): boolean => {
  const elem = document.getElementById(elementId)
  return !!elem && elem.offsetWidth < elem.scrollWidth
}

interface ResizeTextParams {
  elements: NodeListOf<HTMLElement>;
  minSize?: number;
  maxSize?: number;
  step?: number;
  unit?: string;
}

export const resizeText = ({elements, minSize = 10, maxSize = 512, step = 1, unit = 'px'}: ResizeTextParams) => {
  elements.forEach(el => {
    let i = minSize
    let overflow = false

        const parent = el.parentElement

    while (!overflow && i < maxSize) {
        el.style.fontSize = `${i}${unit}`
        overflow = !!parent && isOverflown(parent)

      if (!overflow) i += step
    }

    // revert to last state where no overflow happened
    el.style.fontSize = `${i - step}${unit}`
  })
}

export const countTitle = (items: unknown[], singular: string, plural: string, spacer = NO_BREAK_SPACE) => {
  return `${items.length}${spacer}${items.length > 1 ? plural : singular}`
}

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export type RGB = [number, number, number];

export function contrast(foregroundColor: RGB, backgroundColor: RGB) {
  const foregroundLuminance = luminance(foregroundColor);
  const backgroundLuminance = luminance(backgroundColor);
  return backgroundLuminance < foregroundLuminance
      ? ((backgroundLuminance + 0.05) / (foregroundLuminance + 0.05))
      : ((foregroundLuminance + 0.05) / (backgroundLuminance + 0.05));
}

export function luminance(rgb: RGB) {
  const [r, g, b] = rgb.map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

export function getRgbColorFromHex(hex: string) {
  hex = hex.slice(1);
  const value = parseInt(hex, 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return [r, g, b] as RGB;
}
