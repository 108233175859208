/**
 * Abstraction layer for date operations using the Day.js library.
 * This module provides various utility functions for handling dates and times,
 * including date comparisons, formatting, and manipulation.
 */

// Import Day.js and its plugins
import dayjs from 'dayjs/esm'
import isSameOrBefore from 'dayjs/esm/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)
import isSameOrAfter from 'dayjs/esm/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
import isBetween from 'dayjs/esm/plugin/isBetween'
dayjs.extend(isBetween)
import duration, { DurationUnitType } from 'dayjs/esm/plugin/duration'
dayjs.extend(duration)
import minMax from 'dayjs/esm/plugin/minMax'
dayjs.extend(minMax)
import weekday from 'dayjs/esm/plugin/weekday'
dayjs.extend(weekday)
import localeData from 'dayjs/esm/plugin/localeData'
dayjs.extend(localeData)
import isToday from 'dayjs/esm/plugin/isToday'
dayjs.extend(isToday)
import utc from 'dayjs/esm/plugin/utc'
dayjs.extend(utc)
import timezone from 'dayjs/esm/plugin/timezone'
dayjs.extend(timezone)
import relativeTime from 'dayjs/esm/plugin/relativeTime'
dayjs.extend(relativeTime)

// Export Day.js instance as datelib for consistency
export const datelib = dayjs;

// Constants for special dates and granularities
export const MIN_DATE = dayjs('0001-12-30T00:00:00.000Z');
export const MAX_DATE = dayjs('4001-01-01T12:00:00.000Z');
export const GRANULARITY_SECOND = 'second' as const;
export const GRANULARITY_DAY = 'day' as const;
export const GRANULARITY_MONTH = 'month' as const;
export const GRANULARITY_SAME_DATE = GRANULARITY_DAY;
export const GRANULARITY_SAME_ENTITY = GRANULARITY_SECOND;

// Export utility functions for date operations
export const maxDate = dayjs.max;
export const minDate = dayjs.min;

// Types
export type DatelibType = dayjs.Dayjs;
export type ConfigType = dayjs.ConfigType;
export type ManipulateType = dayjs.ManipulateType;

/**
 * Return the duration of a value in the specified units.
 * @param value The value to convert to a duration.
 * @param units The units to use for the duration.
 * @returns A Day.js duration object.
 */
export const datelibDuration = (value: number, units: DurationUnitType) => dayjs.duration(value, units);

export const MAX_TIMESTAMP = 8640000000000000;
/**
 * A date value representing a date in the distant future.
 */
export const distantFuture = MAX_DATE.toDate();

/**
 * A date value representing a date in the distant past.
 */
export const distantPast = MIN_DATE.toDate();

/**
 * Return noon of the day of a Date object.
 * @param d The input Date object.
 * @returns A new Date object representing noon of the input date.
 */
export const noonOfDate = (d: Date): Date => {
  const date = new Date(d);
  date.setHours(12, 0, 0, 0);
  return date;
};

/**
 * Return the start of the day of a Date object.
 * @param d The input Date object.
 * @param granularity The granularity level for the start of the day (optional).
 * @returns A Day.js instance representing the start of the day.
 */
export const startOfDate = (
  d: dayjs.ConfigType,
  granularity: dayjs.OpUnitType = GRANULARITY_DAY
) => dayjs(d).startOf(granularity);

/**
 * Return the end of the day of a Date object.
 * @param d The input Date object.
 * @param granularity The granularity level for the end of the day (optional).
 * @returns A Day.js instance representing the end of the day.
 */
export const endOfDate = (
  d: dayjs.ConfigType,
  granularity: dayjs.OpUnitType = GRANULARITY_DAY
) => dayjs(d).endOf(granularity);

/**
 * Return the next day as a Date object.
 * @returns A Date object representing the next day.
 */
export const tomorrow = () => dayjs().add(1, 'day').toDate();

/**
 * Return the previous day as a Date object.
 * @returns A Date object representing the previous day.
 */
export const yesterday = () => dayjs().subtract(1, 'day').toDate();

/**
 * Return the next day at midnight as a Date object.
 * @returns A Date object representing the start of the next day.
 */
export const startOfTomorrow = () => dayjs().add(1, 'day').startOf('day').toDate();

/**
 * Return true if two dates are in the same range.
 * @param d1 The first date.
 * @param d2 The second date.
 * @param granularity The granularity level for comparison (optional).
 * @returns A boolean indicating whether the dates are in the same range.
 */
export const inSameRange = (
  d1: dayjs.ConfigType,
  d2: dayjs.ConfigType,
  granularity: dayjs.OpUnitType = GRANULARITY_DAY
) => dayjs(d1).isSame(d2, granularity);

/**
 * Return true if two dates are in the same day.
 * @param d1 The first date.
 * @param d2 The second date.
 * @returns A boolean indicating whether the dates are in the same day.
 */
export const inSameDay = (d1: dayjs.ConfigType, d2: dayjs.ConfigType) => inSameRange(d1, d2);

/**
 * Return true if a date is today.
 * @param d The date to check.
 * @returns A boolean indicating whether the date is today.
 */
export const inToday = (d: Date) => dayjs(d).isToday();

/**
 * Sort an array of objects by their date property.
 * @param items The array of objects.
 * @returns The sorted array of objects.
 */
export const sortedByDate = (items: { date: Date }[]) =>
  items.sort((a, b) => compareByDate(a.date, b.date));

/**
 * Unwrap a date value from ISO 8601 format.
 * @param value The date value in ISO 8601 format.
 * @returns A Date object parsed from the ISO 8601 string.
 */
export const unwrapIsodate = (value?: string | Date) => dayjs(value).toDate();

/**
 * Check if a value is a valid Date object.
 * @param d The value to check.
 * @returns A boolean indicating whether the value is a valid Date object.
 */
export const isValidDate = (d: unknown): d is Date =>
  d instanceof Date && !isNaN(d.getTime());

/**
 * Format a date as a short string representation.
 * @param d The date to format.
 * @returns A short string representation of the date.
 */
export const shortDate = (d: dayjs.ConfigType) =>
  dayjs(d).format('YYYY-MM-DD');

/**
 * Group items by their date property.
 * @param items The array of items to group.
 * @param dateKey The date property to use as the grouping key.
 * @returns An object where keys are dates and values are arrays of items.
 */
export const groupByDate = <T extends Record<K, Date | string>, K extends keyof T>(
  items: T[],
  dateKey: K
): Record<string, T[]> =>
  items.reduce((result, item) => {
    const m = dayjs(item[dateKey]);
    if (!m.isValid()) {
      return result;
    }
    const d = shortDate(m);
    (result[d] = result[d] || []).push(item);
    return result;
  }, {} as Record<string, T[]>);

/**
 * Sort an array of objects by their date property.
 * @param items The array of objects.
 * @param key The date property to use for comparison.
 * @param isAsc A boolean indicating whether to sort in ascending order (default: true).
 * @returns The sorted array of objects.
 */
export const sortByDate = <T extends Record<K, Date | string>, K extends keyof T>(
  items: T[],
  key: K,
  isAsc = true
): T[] =>
  items.length > 1
    ? items.sort((a, b) => compareByDate(a[key], b[key], GRANULARITY_SAME_DATE, isAsc))
    : items;

/**
 * Compare two dates with granularity.
 * @param a The first date as a string in ISO8601 or YYYY-MM-DD format, or a Date object.
 * @param b The second date as a string in ISO8601 or YYYY-MM-DD format, or a Date object.
 * @param granularity The sameness maximum interval (optional).
 * @returns -1 if the first date is before the second date with the specified granularity, otherwise 1.
 */
export const compareDateForGranularity = (
  a: dayjs.ConfigType,
  b: dayjs.ConfigType,
  granularity: dayjs.OpUnitType = GRANULARITY_SAME_DATE
): number => {
  const aDate = dayjs(a);
  const bDate = dayjs(b);
  return aDate.isBefore(bDate, granularity) ? -1 : aDate.isAfter(bDate, granularity) ? 1 : 0;
};

/**
 * Compare two dates with specified granularity.
 * @param a The first date to compare.
 * @param b The second date to compare.
 * @param granularity The granularity level for comparison.
 * @param isAsc A boolean indicating whether to sort in ascending order (default: true).
 * @returns A number indicating the comparison result.
 */
export const compareByDate = (
  a: dayjs.ConfigType,
  b: dayjs.ConfigType,
  granularity: dayjs.OpUnitType = GRANULARITY_SAME_DATE,
  isAsc = true
): number => compareDateForGranularity(a, b, granularity) * (isAsc ? 1 : -1);

/**
 * Extract a valid ISO date string from entity properties.
 * @param options The options object containing properties.
 * @param dateKey The key of the date property.
 * @param fallback The fallback date value.
 * @returns A valid ISO date string.
 */
export const getISODateFrom = <T extends { shouldSave?: boolean; [key: string]: any }>(
  options: T,
  dateKey: string,
  fallback: Date
): Date => {
  const date = options.shouldSave
    ? fallback
    : unwrapIsodate(options[dateKey] ?? fallback);
  return isValidDate(date) ? date : new Date();
};

/**
 * Guess the time zone.
 * @returns The guessed time zone.
 */
export const tzguess = (): string => dayjs.tz.guess();

/**
 * Convert a Day.js instance to a Date object.
 * @param value The Day.js instance or ConfigType to convert.
 * @param defaultValue The fallback date value.
 * @returns A Date object.
 */
export const toDefaultDate = (
  value: dayjs.ConfigType,
  defaultValue: dayjs.ConfigType
): Date =>
  dayjs(value).isValid()
    ? dayjs(value).toDate()
    : dayjs(defaultValue).isValid()
      ? dayjs(defaultValue).toDate()
      : dayjs().toDate();
